<template>
  <div>
    <div v-if="showPricingTable" class="all-apartments">
      <AdminTable
        title="Rooms Pricing"
        :list="roomList"
        :fields="fields"
        :loading="loading"
        :accommodation-types-button="true"
        @show-accommodation-types="showAccommodationTypes"
        @edit="editRoom"
        @update-list="update"
      />
    </div>
    <div v-else>
      <AdminTable
        title="Rooms Accommodation types"
        :list="roomsAccommodationTypesList"
        :fields="accommodationTypesFields"
        :loading="loading"
        :rooms-button="true"
        :rooms-accommodation-types="true"
        @show-rooms="showRooms"
        @edit="editRoomAccommodationType"
        @update-accommodation-type-rooms="updateAccommodationTypeRooms"
        @update-list="update"
      />
    </div>
    <b-modal hide-footer title="Set prices for room" v-model="showPricingModal">
      <ValidationObserver ref="validator">
        <form class="edit-apartment-form" @submit.prevent>
          <b-row>
            <b-col>
              <RFValidation rules="required" name="Monthly Amount">
                <RFCurrencyInput label="Monthly Amount" v-model="room.monthlyAmount"/>
              </RFValidation>
            </b-col>
            <b-col>
              <RFValidation rules="required" name="Deposit Amount">
                <RFCurrencyInput label="Deposit Amount" v-model="room.depositAmount"/>
              </RFValidation>
            </b-col>
            <b-col>
              <RFValidation rules="required" name="Service Fees">
                <RFCurrencyInput label="Service Fees" v-model="room.serviceFees"/>
              </RFValidation>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <RFValidation rules="required" name="Update all">
                <RFCheckbox class="mt-4" label="Update all equal type rooms?" v-model="updateAll" />
              </RFValidation>
            </b-col>
          </b-row>
          <b-row class="justify-content-end mb-5">
            <b-col cols="auto">
              <RFButton class="mt-3" size="small" label="Save" background="#5CB86C" v-if="!loading" @on-click="save"/>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>
    </b-modal>
    <b-modal hide-footer title="Set price to show for room accommodation type" v-model="showRoomAccommodationTypeModal">
      <ValidationObserver ref="accommodationTypeValidator">
        <form class="edit-apartment-form" @submit.prevent>
          <b-row>
            <b-col>
              <RFValidation rules="required|positiveNumber" name="Price to show">
                <RFCurrencyInput label="Price to show" v-model="priceToShow"/>
              </RFValidation>
            </b-col>
          </b-row>
          <b-row class="justify-content-end mb-5">
            <b-col cols="auto">
              <RFButton class="mt-3" size="small" label="Save" background="#5CB86C" v-if="!loading" @on-click="saveRoomAccommodationType"/>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import AdminTable from '@/components/admin/AdminTable'
import RFButton from '@/components/forms/RFButton'
import RFCurrencyInput from '@/components/forms/RFCurrencyInput'
import RFValidation from '@/components/forms/RFValidation'
import RFCheckbox from '@/components/forms/RFCheckbox'
import { roomService, accommodationTypeService } from '@/services/admin'

export default {
  components: {
    AdminTable,
    RFValidation,
    RFButton,
    RFCheckbox,
    RFCurrencyInput
  },
  data() {
    return {
      roomList: [],
      loading: false,
      showPricingModal: false,
      updateAll: false,
      room: {},
      roomsAccommodationTypesList: [],
      showPricingTable: true,
      roomAccommodationType: {},
      showRoomAccommodationTypeModal: false,
      priceToShow: null
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: 'ID' },
        { key: 'name', label: 'Name' },
        { key: 'buildingName', label: 'Building' },
        { key: 'type.name', label: 'Type' },
        { key: 'monthlyAmount', label: 'Monthly Amount', type: 'cash'},
        { key: 'depositAmount', label: 'Deposit Amount', type: 'cash'},
        { key: 'serviceFees', label: 'Service Fees', type: 'cash'},
        { key: 'actions', label: 'Actions', type: 'actions', actions: ['edit'] },
      ]
    },
    accommodationTypesFields(){
      return [
        { key: 'id', label: 'ID' },
        { key: 'name', label: 'Name' },
        { key: 'priceToShow', label: 'Price to show', type: 'cash' },
        { key: 'actions', label: 'Actions', type: 'actions', actions: ['edit','delete'] },
      ]
    }
  },
  async beforeMount() {
    await this.update()
  },
  methods: {
    openImagesModal() {
      this.$root.$emit('bv::show::modal', 'add-images-modal')
    },
    async update() {
      this.loading = true
      this.roomList = this.building ? this.building.rooms : await roomService.getRooms()
      this.roomsAccommodationTypesList = await accommodationTypeService.getRoomsAccommodationTypes()
      this.loading = false
    },
    editRoom(room) {
      Object.assign(this.room, room)
      this.showPricingModal = true
    },
    editRoomAccommodationType(accommodationType){
      Object.assign(this.roomAccommodationType, accommodationType)
      this.priceToShow = this.roomAccommodationType.priceToShow
      this.showRoomAccommodationTypeModal = true
    },
    async showRooms() {
      this.showPricingTable = true
      await this.update()
    },
    async showAccommodationTypes() {
      this.showPricingTable = false
      await this.update()
    },
    async save() {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        let response = null
        if (this.updateAll) {
          console.log('Batch update')
          const roomTypePrice = {
            buildingName: this.room.buildingName,
            typeName: this.room.type.name,
            monthlyAmount: this.room.monthlyAmount,
            depositAmount: this.room.depositAmount,
            serviceFees: this.room.serviceFees
          }
          response = await roomService.batchPricing(roomTypePrice)
        } else {
          response = await roomService.editRoom(this.room)
        }
        console.log(response)
        if (response.status === 200) {
          this.showPricingModal = false
          await this.update()
          this.$toasted.success('Prices updated!')
        } else {
          this.$toasted.error(this.$i18n.t(`Error during edit room`))
        }
      }
    },
    async saveRoomAccommodationType() {
      const isValid = await this.$refs.accommodationTypeValidator.validate()

      if (isValid) {
        this.roomAccommodationType.priceToShow = this.priceToShow
        let response = await accommodationTypeService.editAccommodationType(this.roomAccommodationType)
        if (response.status === 200) {
          this.showRoomAccommodationTypeModal = false
          await this.update()
          this.$toasted.success('Room accommodation type updated!')
        } else {
          this.$toasted.error(this.$i18n.t(`Error during edit room accommodation type`))
        }
      }
    },
    async updateAccommodationTypeRooms(accommodationType,deleteAccommodationTypeRooms){
      const roomAccommodationTypeRequest = {
        deleteAccommodationTypeRooms: deleteAccommodationTypeRooms
      }
      let response = await accommodationTypeService.editRoomsAccommodationTypes(accommodationType,roomAccommodationTypeRequest)
      if (response.status === 200) {
        await this.update()
        if(deleteAccommodationTypeRooms){
          this.$toasted.success('Rooms of accommodation type deleted!')
        }
        else{
          this.$toasted.success('Rooms of accommodation type restored!')
        }
      } else {
        if(deleteAccommodationTypeRooms){
          this.$toasted.error(this.$i18n.t(`Error during delete rooms of accommodation type`))
        }
        else{
          this.$toasted.error(this.$i18n.t(`Error during restore rooms of accommodation type`))
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
b-modal {
  height: auto !important;
}
</style>
